import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Col, Container, Layout, Row } from 'layout';

import Banner from 'components/Banner';

import ControledAccordion from 'components/ControledAccordion';
import SuccessfulSexPageNavigation from 'components/SuccessfulSexPageNavigation';
import ContentArticle from 'components/ContentArticle';

import { ISuccessfulSexPageFAQComponentProps } from './models';
import './SuccessfulSexPageFAQ.scss';

const SuccessfulSexPageFAQ: FC<ISuccessfulSexPageFAQComponentProps> = ({
  data: {
    umbracoSuccessfulSexFaq: {
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
      defaultCompositions,
      url,
      banner,
      sideNavigation,
      accordionSectionFAQ,
      contentSection,
    },
  },
}) => (
  <Layout
    defaultCompositions={defaultCompositions}
    seo={{
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
    }}
    className="successful-faq"
  >
    {banner?.map(({ properties: { title: bannerTitle, ...restBannerProperties } }) => (
      <Banner
        url={url}
        key={bannerTitle}
        title={bannerTitle}
        {...{ ...restBannerProperties, image: restBannerProperties.localImage }}
        className="successful-faq__banner"
      />
    ))}
    <Container className="successful-faq__container">
      <Row>
        <Col lg="4">
          <SuccessfulSexPageNavigation {...{ sideNavigation }} />
        </Col>
        <Col sm="12" lg="8">
          <ContentArticle {...{ contentSection }} />
          {accordionSectionFAQ.map(({ properties: { faqAccordionSection, title, subtitle } }) => (
            <div key={title}>
              <h2 className="successful-faq__accordion-title">{title}</h2>
              <h5 className="successful-faq__accordion-subtitle">{subtitle}</h5>
              <ControledAccordion
                className="successful-faq__accordion"
                items={faqAccordionSection}
              />
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  </Layout>
);

export const query = graphql`
  query SuccessfulSexPageFAQ($url: String = "") {
    umbracoSuccessfulSexFaq(url: { eq: $url }) {
      url
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      banner {
        properties {
          title
        }
      }
      sideNavigation {
        ...FragmentSuccessfulSexPageNavigation
      }
      accordionSectionFAQ {
        ...FragmentAccordionSection
      }
      contentSection {
        properties {
          content
          subtitle
          title
        }
      }
    }
  }
`;

export default SuccessfulSexPageFAQ;
