import React, { FC } from 'react';
import { DangerouslySetInnerHtml } from 'layout';

import { IContentArticleItemComponentProps } from './models';
import 'components/ContentArticle/ContentArticle.scss';

const ContentArticle: FC<IContentArticleItemComponentProps> = ({ title, subtitle, content }) => (
  <div className="content__inner">
    <h2 className="content__title">{title}</h2>
    <h5 className="content__subtitle">{subtitle}</h5>
    <DangerouslySetInnerHtml html={content} />
  </div>
);

export default ContentArticle;
