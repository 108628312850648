import React, { FC } from 'react';

import { IContentArticleComponentProps } from 'components/ContentArticle/models';
import ContentSectionItem from './ContentArticleItem/ContentArticleItem';
import 'components/ContentArticle/ContentArticle.scss';

const ContentArticle: FC<IContentArticleComponentProps> = ({ contentSection }) => (
  <div className="content" data-testid="content-article">
    <div className="content__article">
      {contentSection.map(({ properties: { title, subtitle, content } }) => (
        <ContentSectionItem {...{ title, subtitle, content, key: title }} />
      ))}
    </div>
  </div>
);

export default ContentArticle;
